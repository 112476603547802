import { createMuiTheme } from "@material-ui/core";
import { colorPallette } from "../constants/colorPallette";


const CYAN_BLUE = colorPallette.CYAN_BLUE
const BLUE = colorPallette.BLUE
export default createMuiTheme({
    palette: {
        primary: {
            main: BLUE
        },
        secondary: {
            main: CYAN_BLUE
        }
    },
    typography: {
        fontFamily: "Inter,sans-serif",
        h1: {
            color: CYAN_BLUE
        },
        h2: {
            color: CYAN_BLUE
        },
        h3: {
            color: CYAN_BLUE
        },
        h4: {
            color: CYAN_BLUE
        },

        h5: {
            color: CYAN_BLUE,
            fontSize: '16px',
            fontWeight: 500
        },
        h6: {
            color: CYAN_BLUE,
            fontSize: '15px'
        }
    }
});