import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INITIAL_STATE } from "./types";

const initialState: INITIAL_STATE = {
	loading: false,
	error: null,
	data: [],
};

const membersSplice = createSlice({
	name: "members",
	initialState,
	reducers: {
		getMembers: (state, action: PayloadAction<{ orgId: string }>) => {
			const temp = state.data.find(
				(el) => el.org === action.payload.orgId
			);
			state.loading = temp && temp.list ? false : true;
			state.error = null;
		},
		setMembers: (
			state,
			action: PayloadAction<{
				orgId: string;
				data: any;
			}>
		) => {
			state.loading = false;
			state.error = null;
			const index = state.data.findIndex(
				(el) => el.org === action.payload.orgId
			);
			if (index > -1)
				state.data[index] = {
					org: action.payload.orgId,
					list: action.payload.data,
				};
			else
				state.data.push({
					org: action.payload.orgId,
					list: action.payload.data,
				});
		},
		errorMembers: (state, action: PayloadAction<{ message: string }>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
	},
});

export const { getMembers, setMembers, errorMembers } = membersSplice.actions;

export default membersSplice.reducer;
