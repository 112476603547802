import React from "react";

import styles from "../styles.module.css";

interface Props {
    caption?: string
}

const LoaderContent = ({caption = 'Loading data...'}: Props) => {
	return (
		<div className={styles.content}>
			<svg
				className={styles.logoloader}
				width="40"
				height="60"
				viewBox="0 0 460 480"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 229.565C0 102.78 102.975 0 230 0C357.026 0 460 102.78 460 229.565V354.783C460 423.938 403.832 480 334.545 480H125.455C56.1679 480 0 423.938 0 354.783V229.565ZM83.6364 333.913C83.6364 299.335 111.72 271.304 146.364 271.304H313.636C348.28 271.304 376.364 299.335 376.364 333.913C376.364 368.491 348.28 396.522 313.636 396.522H146.364C111.72 396.522 83.6364 368.491 83.6364 333.913ZM208.653 206.622C207.514 212.083 214.599 215.279 217.953 210.817L276 133.565C278.585 130.126 276.126 125.217 271.818 125.217H249.602C246.009 125.217 243.706 122.16 244.486 118.424L251.347 85.5515C252.487 80.0906 245.401 76.8946 242.048 81.3571L184 158.609C181.415 162.048 183.874 166.957 188.182 166.957H210.398C213.991 166.957 216.294 170.014 215.514 173.752L208.653 206.622Z"
					fill="#05C46B"
				/>
				<circle cx="180" cy="335" r="30" fill="#05C46B" />
				<circle cx="280" cy="335" r="30" fill="#05C46B" />
			</svg>

			<h1 className={styles.loadingText}>{caption}</h1>
		</div>
	);
};

export default LoaderContent;
