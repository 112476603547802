export const SAVE_VIEW = 'SAVE_VIEW';
export const GET_VIEW = 'GET_VIEW';
export const DELETE_VIEW = 'DELETE_VIEW';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const CHECKLISTFILTER = 'CHECKLISTFILTER';
export const SHOW_VIEW_MODEL = 'SHOW_VIEW_MODEL';
export const SAVE_GROUP = 'SAVE_GROUP';
export const TEMPLATE_ID = 'TEMPLATE_ID';

export interface SaveMyView {
    type: typeof SAVE_VIEW,
    payload: {
      data:any
    }
  }
  export interface GetMyView {
    type: typeof GET_VIEW
   
  }
  export interface UpdateView {
    type: typeof UPDATE_VIEW,
    payload: {
      data:any
    }
  }
  export interface Setloading {
    type: typeof LOADING,
    payload: {
      loading:boolean
    }
  }
  export interface SetSuccess {
    type: typeof SUCCESS,
    payload: {
      success:boolean,
      loading:boolean
    }
  }
  export interface SetChecklist_Filter {
    type: typeof CHECKLISTFILTER,
    payload: {
      data:any
    }
  }
  export interface SetModelVisible {
    type: typeof SHOW_VIEW_MODEL,
    payload: {
      view_model:boolean
    }
  }
  export interface SaveGroup {
    type: typeof SAVE_GROUP,
    payload: {
      data:any
    }
  }
  export interface Delete_view {
    type: typeof DELETE_VIEW,
    payload: {
      id:string
    }
  }
  export interface Template_filter {
    type: typeof TEMPLATE_ID,
    payload: {
      data:any
    }
  }

  export type CustomViewActionTypes = SaveMyView | GetMyView | UpdateView | Setloading | SetSuccess | SetChecklist_Filter | SetModelVisible | SaveGroup | Delete_view | Template_filter;