export const ACTIVITY = 'ACTIVITY';
export const ACTIVITY_ERROR = 'ACTIVITY_ERROR';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_LOADING = 'ACTIVITY_LOADING ';
export const ACTIVITY_CLEAR_ERROR = 'ACTIVITY_CLEAR_ERROR';
export const ACTIVITY_CLEAR_SUCCESS = 'ACTIVITY_CLEAR_SUCCESS';

export interface newComment {
  description: string,
}

export interface ActivityAction {
  type: typeof ACTIVITY,
  payload: {
    activities: any
  }
}


export interface ActivitySuccessAction {
  type: typeof ACTIVITY_SUCCESS
}

export interface ActivityClearSuccessAction {
  type: typeof ACTIVITY_CLEAR_SUCCESS
}

export interface ActivityErrorAction {
  type: typeof ACTIVITY_ERROR,
  payload: {
    message: string
  }
}

export interface ActivityLoadingAction {
  type: typeof ACTIVITY_LOADING,
}

export interface ActivityClearErrorAction {
  type: typeof ACTIVITY_CLEAR_ERROR,
}

export type ActivityActionTypes = ActivityAction | ActivityErrorAction | ActivityClearErrorAction | ActivityLoadingAction | ActivitySuccessAction | ActivityClearSuccessAction;
