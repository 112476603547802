export const CHECKLISTS = 'CHECKLISTS';
export const TEMPLATE_CHECKLISTS = 'TEMPLATE_CHECKLISTS';
export const INBOX_CHECKLISTS = 'INBOX_CHECKLISTS';
export const CHECKLIST_ERROR = 'CHECKLIST_ERROR';
export const CHECKLIST_SUCCESS = 'CHECKLIST_SUCCESS';
export const CHECKLIST_LOADING = 'CHECKLIST_LOADING ';
export const DELETE_CHECKLIST = 'DELETE_CHECKLIST';
export const ADD_DUEDATE = 'ADD_DUEDATE';
export const GET_TASKS = "GET_TASKS";
export const CHECKLIST_CLEAR_ERROR = 'CHECKLIST_CLEAR_ERROR';
export const CHECKLIST_CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const COMPLETE_CHECKLIST = 'COMPLETE_CHECKLIST';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const COMPLETE_TASK_CHECK = 'COMPLETE_TASK_CHECK';
export const SCHEDULE_TASK = 'SCHEDULE_TASK';
export const ASSIGN_TASK = 'ASSIGN_TASK';
export const ALL_TASKS_DUEDATE = 'ALL_TASKS_DUEDATE';
export const RETRACT_ASSIGNMENT = 'RETRACT_ASSIGNMENT';
export const ALL_COMMENT_DATA = 'ALL_COMMENT_DATA';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const COMPLETE_ARCHIVE = 'COMPLETE_ARCHIVE';
export const UNARCHIVE_CHECKLIST = 'UNARCHIVE_CHECKLIST';
export const INBOX_CHECKLISTS_LENGTH = 'INBOX_CHECKLISTS_LENGTH';
export const SINGLE_TASK = 'SINGLE_TASK';
export const SINGLE_CHECKLIST = 'SINGLE_CHECKLIST';

export interface newComment {
  description: string,
}

export interface ChecklistsAction {
  type: typeof CHECKLISTS,
  payload: {
    checklists: any
  }
}
export interface TemplateChecklistsAction {
  type: typeof TEMPLATE_CHECKLISTS,
  payload: {
    checklists: any
  }
}

export interface InboxChecklistsAction {
  type: typeof INBOX_CHECKLISTS,
  payload: {
    checklists: any
  }
}

export interface InboxChecklistsLengthAction {
  type: typeof INBOX_CHECKLISTS_LENGTH,
  payload: {
    checklistsData: any
  }
}


export interface DeleteChecklistAction {
  type: typeof DELETE_CHECKLIST,
  payload: {
    checklistId: string
  }
}

export interface AddDueDateChecklistAction {
  type: typeof ADD_DUEDATE,
  payload: {
    checklistId: string,
    dueDate: string
  }
}
export interface CompleteChecklistAction {
  type: typeof COMPLETE_CHECKLIST,
  payload: {
    checklistId: string
    isCompleted: boolean
  }
}
export interface CompleteArchiveAction {
  type: typeof COMPLETE_ARCHIVE,
  payload: {
    checklistId: string
  }
}
export interface UnArchiveAction {
  type: typeof UNARCHIVE_CHECKLIST,
  payload: {
    checklistId: string
  }
}

export interface CompleteChecklistTaskAction {
  type: typeof COMPLETE_TASK,
  payload: {
    checklistId: string,
    taskId: string,
    checked: boolean
  }
}

export interface AssignChecklistTaskAction {
  type: typeof ASSIGN_TASK,
  payload: {
    checklistId: string,
    taskId: string,
    assignees: any
  }
}

export interface TaskDueDateAction {
  type: typeof ALL_TASKS_DUEDATE,
  payload: {
    all_tasks_duedate: any
  }
}

export interface RetractAssignmentTaskAction {
  type: typeof RETRACT_ASSIGNMENT,
  payload: {
    checklistId: string,
    taskId: string,
    assignees: any
  }
}

export interface ScheduleChecklistTaskAction {
  type: typeof SCHEDULE_TASK,
  payload: {
    checklistId: string,
    taskId: string,
    dueDate: string
  }
}
export interface GetTasks {
  type: typeof GET_TASKS,
  payload: { tasks: any[] }
}
export interface GetSingleTasks {
  type: typeof SINGLE_TASK,
  payload: { task: any[] }
}

export interface CommentAllAction {
  type: typeof ALL_COMMENT_DATA,
  payload: {
    comment_data: any
  }
}

export interface DeleteTaskComment {
  type: typeof DELETE_COMMENT,
  payload: {
    commentId: string,
  }
}

export interface ChecklistSuccessAction {
  type: typeof CHECKLIST_SUCCESS
}

export interface ChecklistClearSuccessAction {
  type: typeof CHECKLIST_CLEAR_SUCCESS
}

export interface ChecklistErrorAction {
  type: typeof CHECKLIST_ERROR,
  payload: {
    message: string
  }
}

export interface ChecklistLoadingAction {
  type: typeof CHECKLIST_LOADING,
}

export interface TemplateClearErrorAction {
  type: typeof CHECKLIST_CLEAR_ERROR,
}
export interface GetSingleChecklist {
  type: typeof SINGLE_CHECKLIST,
  payload: {singleChecklist : any}
}
export type ChecklistActionTypes = ChecklistsAction | TemplateChecklistsAction | GetSingleTasks | InboxChecklistsAction | RetractAssignmentTaskAction | AssignChecklistTaskAction | ScheduleChecklistTaskAction | CompleteChecklistTaskAction | CompleteChecklistAction | TemplateClearErrorAction | DeleteChecklistAction |
  ChecklistErrorAction | ChecklistLoadingAction | ChecklistSuccessAction | ChecklistClearSuccessAction | AddDueDateChecklistAction | GetTasks | TaskDueDateAction | CommentAllAction | DeleteTaskComment | CompleteArchiveAction | UnArchiveAction | InboxChecklistsLengthAction | GetSingleChecklist;
