export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_RESET = "REGISTER_RESET";
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const REGISTER_EMAIL = "REGISTER_EMAIL";
export const REGISTER_USER_EXIST = "REGISTER_USER_EXIST";
export const REGISTER_SUCCESS_CLEAR = "REGISTER_SUCCESS_CLEAR";
export const INVITE_MEMBERS = "INVITE_MEMBERS";
export const CLEAR_INVITE_MEMBERS = "CLEAR_INVITE_MEMBERS";
export interface newUser {
    email?: string,
    password: string,
    full_name?: string,
    contact_number?: string,
    company_name?: string,
    industry?: string,
    role?: string,
    time_zone?:string,
}
export interface inviteMembers {
	members?: []
}

export interface RegisterAction {
    type: typeof REGISTER,
    payload: {
        newUser: newUser
    }
}

export interface AcceptInvitationAction {
    type: typeof ACCEPT_INVITATION,
    payload: {
       success: boolean
    }
}

export interface RegisterSuccessAction {
    type: typeof REGISTER_SUCCESS,
}
export interface RegisterErrorAction {
    type: typeof REGISTER_ERROR,
    payload: {
        message: string
    }
}
export interface RegisterationEmail {
    type: typeof REGISTER_EMAIL,
    payload: {
        message: string
    }
}
export interface RegisterLoadingAction {
    type: typeof REGISTER_LOADING,
}

export interface RegisterReset {
    type: typeof REGISTER_RESET,
}

export interface InviteMembers {
    type: typeof INVITE_MEMBERS,
}
export interface ClearInviteMembers {
    type: typeof CLEAR_INVITE_MEMBERS,
}
export interface CheckUserExist {
    type: typeof REGISTER_USER_EXIST,
    payload: {
        userExist: any
    }
}
export interface ClearSuccess {
    type: typeof REGISTER_SUCCESS_CLEAR,
}

export type RegisterActionTypes = RegisterAction | AcceptInvitationAction | RegisterationEmail | RegisterErrorAction | RegisterLoadingAction | RegisterReset | CheckUserExist | ClearSuccess | InviteMembers | ClearInviteMembers |RegisterSuccessAction;
