import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Routes } from "./routes";
import { withRouter } from "react-router-dom";
import FBPixel from "./utils/FBPixel";
import { ConfigProvider } from "antd";
import { locales, getLocale } from "./utils/locales";
import { Empty } from "antd";
import { connect } from "react-redux";
import { appState } from "./store/store";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core';
import { notify } from "./components/notification";

const useStyles = makeStyles((theme) => ({}));

const App: React.FC = (props: any) => {
  // eslint-disable-next-line
  const classes = useStyles();
  const [isOnline, setIsOnline] = useState(true)

  const { t } = useTranslation();
  const { location, userInfo } = props;

  useEffect(() => {
    FBPixel.pageView();
  }, [location, location.pathname]);

  useEffect(() => {
    FBPixel.fbq("track", "PageView");
  }, [location, location.pathname]);

  const language = userInfo && userInfo.language;
  const locale = getLocale(language);

  window.addEventListener('offline', function(e) { setIsOnline(false) });
  window.addEventListener('online', function(e) { setIsOnline(true) });
  useEffect(() => {
    if(!isOnline){
      notify("warning", "Offline");
    }
  }, [isOnline]);

  return (
    <ConfigProvider
      locale={locales[locale]}
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("No data")}
        />
      )}
    >
      <Routes />
    </ConfigProvider>
  );
};

const mapStateToProps = (state: appState) => ({
  userInfo: state.loginReducer.userInfo,
});

export default connect(mapStateToProps, null)(withRouter(App));
