// Register Reducer

import { REGISTER, INVITE_MEMBERS, CLEAR_INVITE_MEMBERS, REGISTER_SUCCESS_CLEAR , REGISTER_USER_EXIST , REGISTER_SUCCESS,REGISTER_EMAIL, REGISTER_ERROR, REGISTER_LOADING, REGISTER_RESET, RegisterActionTypes } from "../types/Register";


interface DefaultStateI {
    newUser?: any
    registration?: boolean,
    error?: string,
    loading?: boolean,
    emailAddr?: string,
    userExist?: any,
    isInvitedMembers?: boolean
}
const registerReducerinitialState: DefaultStateI = {
	newUser: "",
    registration: false,
    error: "",
    loading: false,
    emailAddr: "",
    userExist: "",
    isInvitedMembers: false
};

const registerReducer = (state: DefaultStateI = registerReducerinitialState, action: RegisterActionTypes): DefaultStateI => {
    switch (action.type) {
        case REGISTER: return { newUser: action.payload.newUser }
        case REGISTER_SUCCESS: return { ...state, registration: true }
        case REGISTER_LOADING: return { ...state, loading: true }
        case REGISTER_ERROR: return { ...state, error: action.payload.message, loading: false }
        case REGISTER_USER_EXIST: return { ...state, userExist: action.payload.userExist, loading: false }
        case REGISTER_RESET: return { ...state, registration: false, error: "", loading: false, userExist: "", emailAddr: "",}
        case REGISTER_EMAIL: return { ...state, emailAddr: action.payload.message,}
        case REGISTER_SUCCESS_CLEAR: return { ...state, registration : false , loading : false}
        case INVITE_MEMBERS: return {...state, isInvitedMembers:true}
        case CLEAR_INVITE_MEMBERS: return {...state, isInvitedMembers:false, loading: false}

    }
    return state;
};
export { registerReducer }