// eslint-disable-next-line
import { ACTIVITY,ACTIVITY_ERROR,ACTIVITY_SUCCESS,ACTIVITY_CLEAR_SUCCESS,ACTIVITY_CLEAR_ERROR,ACTIVITY_LOADING } from "../types/Activity";
import { ActivityActionTypes } from "../types/Activity";

interface DefaultStateI {
	activities: any,
	error?: string,
	loading?: boolean,
	success?: boolean
}
const activityReducerinitialState: DefaultStateI = {
	activities: [],
	error: "",
	loading: false,
	success: false
};

const activityReducer = (state: DefaultStateI = activityReducerinitialState, action: ActivityActionTypes): DefaultStateI => {

	switch (action.type) {
		case ACTIVITY: return { ...state, activities: action.payload.activities, loading: false }
		case ACTIVITY_SUCCESS: return { ...state, success: true, loading: false }
		case ACTIVITY_CLEAR_SUCCESS: return { ...state, success: false, loading: false }
		case ACTIVITY_CLEAR_ERROR: return { ...state, error: "", loading: false }
		case ACTIVITY_LOADING: return { ...state, loading: true }
		case ACTIVITY_ERROR: return { ...state, error: action.payload.message, loading: false }
	}
	return state;
};
export { activityReducer }