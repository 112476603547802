import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INITIAL_STATE } from "./types";

const initialState: INITIAL_STATE = {
	inbox: {
		loading: false,
		error: null,
		data: [],
	},
	upcoming: {
		loading: false,
		error: null,
		data: [],
	},
};

const inboxSplice = createSlice({
	name: "inbox",
	initialState,
	reducers: {
		getInbox: (
			state,
			action: PayloadAction<{ tab: string, orgId: string, userId: string }>
		) => {
			const sourceObj =
				action.payload.tab === "inbox" ? state.inbox : state.upcoming;
			const temp = sourceObj.data.find(
				(el) => el.org === action.payload.orgId && el.user === action.payload.userId
			);
			sourceObj.loading = temp && temp.list ? false : true;
			sourceObj.error = null;
			state[action.payload.tab] = sourceObj;
		},
		setInbox: (
			state,
			action: PayloadAction<{
				tab: string;
				userId: string;
				orgId: string;
				data: any;
			}>
		) => {
			const sourceObj =
				action.payload.tab === "inbox" ? state.inbox : state.upcoming;

			sourceObj.loading = false;
			sourceObj.error = null;
			const index = sourceObj.data.findIndex(
				(el) =>
					el.org === action.payload.orgId &&
					el.user === action.payload.userId
			);
			if (index > -1)
				sourceObj.data[index] = {
					org: action.payload.orgId,
					user: action.payload.userId,
					list: action.payload.data,
				};
			else
				sourceObj.data.push({
					org: action.payload.orgId,
					user: action.payload.userId,
					list: action.payload.data,
				});
			state[action.payload.tab] = sourceObj;
		},
		errorInbox: (state, action: PayloadAction<{ message: string }>) => {
			// state.loading = false;
			// state.error = action.payload.message;
		},
	},
});

export const { getInbox, setInbox, errorInbox } = inboxSplice.actions;

export default inboxSplice.reducer;
