export const DASHBOARD_CARDS = "DASHBOARD_CARDS";
export const DAILY_TOTAL_USERS = "DAILY_TOTAL_USERS";
export const DAILY_ACTIVE_USERS = "DAILY_ACTIVE_USERS";
export const USERS = "USERS";
export const CHECKLISTS = "CHECKLISTS";
export const TEMPLATES = "TEMPLATES";
export const ORGANIZATIONS = "ORGANIZATIONS";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const CLEAR_ERROR = "CLEAR_ERROR";


export type dashboardCards = {
  users: string;
  checklists: string;
  templates: string;
  organization: string;
};

export interface dashboardCardsAction {
  type: typeof DASHBOARD_CARDS;
  payload: {
    dashboardCards: dashboardCards;
  };
}

export interface dailyTotalUsers {
  type: typeof DAILY_TOTAL_USERS;
  payload: {
    totalusers: any;
  };
}
export interface dailyActiveUsers {
  type: typeof DAILY_ACTIVE_USERS;
  payload: {
    activeUsers: any;
  };
}

export interface users {
  type: typeof USERS;
  payload: {
    users: any;
  };
}

export interface checklists {
  type: typeof CHECKLISTS;
  payload: {
    checklists: any;
  };
}
export interface organizations {
  type: typeof ORGANIZATIONS;
  payload: {
    organizations: any;
  };
}
export interface templates {
  type: typeof TEMPLATES;
  payload: {
    templates: any;
  };
}
export interface adminPanelError {
  type: typeof ADMIN_ERROR;
  payload: {
    message: string;
  };
}

export interface clearError {
  type: typeof CLEAR_ERROR;
}
export interface adminPanelLoading {
  type: typeof ADMIN_LOADING,
}

export type adminActionTypes =
  | dashboardCardsAction
  | adminPanelError
  | adminPanelLoading
  | dailyTotalUsers
  | dailyActiveUsers
  | users
  | checklists
  | templates
  | organizations
  | clearError;
