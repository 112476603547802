// eslint-disable-next-line
import { CHECKLISTS, TEMPLATE_CHECKLISTS, SCHEDULE_TASK, INBOX_CHECKLISTS, RETRACT_ASSIGNMENT,SINGLE_TASK, ASSIGN_TASK, COMPLETE_TASK, CHECKLIST_ERROR, ADD_DUEDATE, CHECKLIST_LOADING, DELETE_CHECKLIST, CHECKLIST_CLEAR_ERROR, COMPLETE_CHECKLIST, CHECKLIST_SUCCESS, CHECKLIST_CLEAR_SUCCESS, GET_TASKS, ALL_TASKS_DUEDATE, ALL_COMMENT_DATA, COMPLETE_ARCHIVE, UNARCHIVE_CHECKLIST, INBOX_CHECKLISTS_LENGTH , SINGLE_CHECKLIST} from "../types/Checklist";
import { ChecklistActionTypes } from "../types/Checklist";

interface DefaultStateI {
	checklists: any,
	checklistsData: any,
	tasks: any,
	all_tasks_duedate?: any
	singleChecklist: any,
	comment_data: any,
	error?: string,
	loading?: boolean,
	success?: boolean,
	single_task ?: any
}
const checklistReducerinitialState: DefaultStateI = {
	checklists: [],
	checklistsData: [],
	tasks: [],
	single_task:{},
	comment_data: [],
	all_tasks_duedate: [],
	singleChecklist: {},
	error: "",
	loading: false,
	success: false
};

const checklistReducer = (state: DefaultStateI = checklistReducerinitialState, action: ChecklistActionTypes): DefaultStateI => {

	switch (action.type) {
		case CHECKLISTS: return { ...state, checklists: action.payload.checklists, loading: false }
		case TEMPLATE_CHECKLISTS: return { ...state, checklists: action.payload.checklists, loading: false }
		case INBOX_CHECKLISTS: return { ...state, checklists: action.payload.checklists, loading: false }
		case INBOX_CHECKLISTS_LENGTH: return { ...state, checklistsData: action.payload.checklistsData, loading: false }
		case DELETE_CHECKLIST: return { ...state, checklists: state.checklists.filter((checklist: any) => checklist._id !== action.payload.checklistId) }
		case ADD_DUEDATE:
			let scheduleCheck = state.checklists;
			scheduleCheck.forEach((task, index) => { if (task._id === action.payload.checklistId) { scheduleCheck[index].dueDate = action.payload.dueDate; } })
			return { ...state, success: true, checklists: scheduleCheck }
		case SCHEDULE_TASK:
			let scheduleTemp = state.tasks;
			let scheduleTempInbox = state.all_tasks_duedate;
			scheduleTemp.forEach((task, index) => { if (task._id === action.payload.taskId) { scheduleTemp[index].dueDate = action.payload.dueDate; } })
			if(scheduleTempInbox.length > 0){
				scheduleTempInbox.forEach((task, index) => { if (task._id === action.payload.taskId) { scheduleTempInbox[index].dueDate = action.payload.dueDate; } })
				return { ...state, success: true, tasks: scheduleTemp, all_tasks_duedate: scheduleTempInbox }
			}
			return { ...state, success: true, tasks: scheduleTemp }
		case ASSIGN_TASK:
			let assignTemp = state.tasks;
			let assignTempInbox = state.all_tasks_duedate;		
			assignTemp.forEach((task, index) => { if (task._id === action.payload.taskId) { assignTemp[index].assignees = action.payload.assignees; } })
			if(assignTempInbox.length > 0){
				assignTempInbox.forEach((task, index) => { if (task._id === action.payload.taskId) { assignTempInbox[index].assignees = action.payload.assignees; } })
				return { ...state, success: true, tasks: assignTemp, all_tasks_duedate: assignTempInbox }
			}
			return { ...state, success: true, tasks: assignTemp }
		case RETRACT_ASSIGNMENT:
			let retractTemp = state.tasks;
			let retractTempInbox = state.all_tasks_duedate;
			retractTemp.forEach((task, index) => { if (task._id === action.payload.taskId) { retractTemp[index].assignees = action.payload.assignees; } })
			if(retractTempInbox.length > 0){
				retractTempInbox.forEach((task, index) => { if (task._id === action.payload.taskId) { retractTempInbox[index].assignees = action.payload.assignees; } })
				return { ...state, success: true, tasks: retractTemp, all_tasks_duedate: retractTempInbox }
			}
			return { ...state, success: true, tasks: retractTemp }

		case COMPLETE_CHECKLIST:
			let completeTemp = state.tasks;
			completeTemp.forEach((task, index) => { if (action.payload.isCompleted === true) { completeTemp[index].isCompleted = true } })

			return { ...state, tasks: completeTemp, checklists: state.checklists.map((checklist: any) => { if (checklist._id === action.payload.checklistId) { checklist.isCompleted = action.payload.isCompleted; return checklist; } return checklist; }) }

		case COMPLETE_ARCHIVE:
			const archiveduser: any = localStorage.getItem("userInfo");
			let archived_checklist = state.checklists
			archived_checklist.forEach((data, index) => { if (data._id === action.payload.checklistId) { archived_checklist[index].archive = JSON.parse(archiveduser) } })
			return { ...state, checklists: archived_checklist }
		case UNARCHIVE_CHECKLIST:
			let un_archived_checklist = state.checklists
			un_archived_checklist.forEach((data, index) => { if (data._id === action.payload.checklistId) { un_archived_checklist[index].archive = null } })
			return { ...state, checklists: un_archived_checklist }

		case COMPLETE_TASK:
			let completeTask: any = Array.from(state.tasks);
			completeTask.forEach((task, index) => { if (task._id === action.payload.taskId) { completeTask[index].isCompleted = action.payload.checked; } })
			return { ...state, success: true, tasks: completeTask }
		case GET_TASKS: return { ...state, loading: false, tasks: action.payload.tasks }
		case SINGLE_TASK: return { ...state, loading: false, single_task: action.payload.task }
		case CHECKLIST_SUCCESS: return { ...state, success: true, loading: false }
		case CHECKLIST_CLEAR_SUCCESS: return { ...state, success: false, loading: false }
		case CHECKLIST_CLEAR_ERROR: return { ...state, error: "", loading: false }
		case CHECKLIST_LOADING: return { ...state, loading: true }
		case CHECKLIST_ERROR: return { ...state, error: action.payload.message, loading: false }
		case ALL_COMMENT_DATA: return { ...state, comment_data: action.payload.comment_data, loading: false }
		case ALL_TASKS_DUEDATE: return { ...state, all_tasks_duedate: action.payload.all_tasks_duedate.data, loading: false }
		case SINGLE_CHECKLIST:return { ...state, singleChecklist: action.payload.singleChecklist, loading: false }
	}
	return state;
};
export { checklistReducer }