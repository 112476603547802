import { DASHBOARD_CARDS, ADMIN_ERROR, ADMIN_LOADING, dashboardCards,adminActionTypes, CLEAR_ERROR,
	DAILY_TOTAL_USERS, DAILY_ACTIVE_USERS , USERS, CHECKLISTS , TEMPLATES , ORGANIZATIONS } from "../types/admin-panel";
interface DefaultStateI {
    dashboardCards?: dashboardCards,
    loading?: boolean,
    error?: string,
    dailyTotalUsers?: any,
    dailyActiveUsers?: any,
    users?: any,
    checklists?: any,
    templates?: any,
    organizations?: any,

}

const adminReducerinitialState: DefaultStateI = {
    dashboardCards: {
        users: "",
        checklists: "",
        templates: "",
        organization: ""
    },
    dailyTotalUsers: [],
    dailyActiveUsers: [],
    users: [],
    checklists:[],
    templates:[],
    organizations:[],
    error: "",
    loading: false
};

const adminReducer = (state: DefaultStateI = adminReducerinitialState, action: adminActionTypes): DefaultStateI => {
    switch (action.type) {
        case DASHBOARD_CARDS: return { ...state, dashboardCards: action.payload.dashboardCards, loading: false }
        case ADMIN_LOADING: return { loading: true }
        case ADMIN_ERROR: return { ...state, error: action.payload.message, loading: false }
        case DAILY_TOTAL_USERS: return { ...state, dailyTotalUsers: action.payload.totalusers, loading: false }
        case DAILY_ACTIVE_USERS: return { ...state, dailyActiveUsers: action.payload.activeUsers, loading: false }
        case USERS: return { ...state, users: action.payload.users, loading: false }
        case CHECKLISTS: return { ...state, checklists: action.payload.checklists, loading: false }
        case TEMPLATES: return { ...state, templates: action.payload.templates, loading: false }
        case ORGANIZATIONS: return { ...state, organizations: action.payload.organizations, loading: false }
        case CLEAR_ERROR: return { ...state, error: "", loading: false }
    }
    return state;
};
export { adminReducer }