
import { OrganizationActionTypes } from "../types/organization";
import { ORGANIZATION_ERROR, ORGANIZATION_LOADING, ORGANIZATION_CLEAR_ERROR, ORGANIZATION_SUCCESS, ORGANIZATION_CLEAR_SUCCESS, ORGANIZATIONS, DELETE_ORGANIZATION_SUCCESS, SET_DEFAULT_ORGANIZATION, GET_DEFAULT_ORGANIZATION, GET_ORGANIZATION_BY_ID, CREARE_ORGANIZATION_GROUP, ORGANIZATION_GROUPS, UPDATE_ORGANIZATION_GROUP, DELETE_ORGANIZATION_GROUP, SELECTED_GROUP } from "../types/organization";

interface DefaultStateI {
    organizations?: any, error?: string, loading?: boolean, success?: boolean, defaultOrganization?: any, organizationById?: any, organizationGroups?: any, successGroup?: boolean, selectedGroupData?: any
}
const organizationReducerinitialState: DefaultStateI = {
    organizations: [], error: "", loading: false, success: false, defaultOrganization: {}, organizationById: {}, organizationGroups: [], successGroup: false, selectedGroupData: {}
};

const organizationReducer = (state: DefaultStateI = organizationReducerinitialState, action: OrganizationActionTypes): DefaultStateI => {

    switch (action.type) {
        case ORGANIZATIONS: return { ...state, organizations: action.payload.organizations, loading: false }
        case ORGANIZATION_LOADING: return { ...state, loading: true }
        case ORGANIZATION_ERROR: return { ...state, error: action.payload.message, loading: false }
        case ORGANIZATION_CLEAR_ERROR: return { ...state, error: "", loading: false }
        case ORGANIZATION_SUCCESS: return { ...state, error: "", success: true, loading: false }
        case ORGANIZATION_CLEAR_SUCCESS: return { ...state, success: false }
        case DELETE_ORGANIZATION_SUCCESS: return { ...state, success: true, loading: false, error: "", }
        case SET_DEFAULT_ORGANIZATION: return { ...state, success: true, loading: false, error: "", }
        case GET_DEFAULT_ORGANIZATION: return { ...state, loading: false, error: "", defaultOrganization: action.payload.defaultOrganization }
        case GET_ORGANIZATION_BY_ID: return { ...state, loading: false, error: "", organizationById: action.payload.organizationById }
        case CREARE_ORGANIZATION_GROUP: return { ...state, successGroup: true, loading: false, error: "", }
        case ORGANIZATION_GROUPS: return { ...state, successGroup: true, loading: false, error: "", organizationGroups: action.payload.organizationGroups }
        case UPDATE_ORGANIZATION_GROUP: return { ...state, success: true, loading: false, error: "", }
        case DELETE_ORGANIZATION_GROUP: return { ...state, success: true, loading: false, error: "", }
        case SELECTED_GROUP: return { ...state, selectedGroupData: action.payload.selectedGroupData, success: true, loading: false, error: "", }
    }
    return state;
};
export { organizationReducer }