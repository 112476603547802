// Register Reducer
import { PROFILE_DATA, CLEAR_PROFILE_SUCCESS_MESSAGE , PROFILE_SUCCESS_MESSAGE , PROFILE_URL_DATA, PROFILE_ERROR, PROFILE_LOADING, ProfileActionTypes, newUser, PROFILE, PROFILE_SUCCESS, PROFILE_IMG_DATA } from "../types/Profile";

interface DefaultStateI {
    profile_data?: any, profile_url_data?: any, error?: string, loading?: boolean, success?: boolean, newUser?: newUser, update_data?: any, image_data?: any
}
const profileReducerinitialState: DefaultStateI = {
    profile_data: [], profile_url_data: [], error: "", loading: false, success: false, update_data: [], image_data: []
};

const profileReducer = (state: DefaultStateI = profileReducerinitialState, action: ProfileActionTypes): DefaultStateI => {
    switch (action.type) {
        case PROFILE_DATA: return { ...state, profile_data: action.payload.profile_data, loading: false }
        case PROFILE_URL_DATA: return { ...state, profile_url_data: action.payload.profile_url_data, loading: false }
        case PROFILE_LOADING: return { ...state, loading: true }
        case PROFILE_SUCCESS_MESSAGE: return { ...state, success: true }
        case CLEAR_PROFILE_SUCCESS_MESSAGE: return { ...state, success: false }
        case PROFILE_ERROR: return { ...state, error: action.payload.message, loading: false }
        case PROFILE: return { newUser: action.payload.newUser }
        case PROFILE_SUCCESS: return { ...state, update_data: action.payload.update_data, loading: false }
        case PROFILE_IMG_DATA: return { ...state, image_data: action.payload.image_data, loading: false }
    }
    return state;
};
export { profileReducer }