import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChecklistsState } from "./types";


const initialState: ChecklistsState = {
    columns: [],
    checklists: {
        loading: false,
        error: null,
        success: null,
        data: [],
        totalCount: 0,
        totalPages:0
    },
    checklist: {
        loading: false,
        error: null,
        success: null,
    },
    templates: {
        loading: false,
        error: null,
        data: [],
    },
    views: {
        loading: false,
        success: null,
        error: null,
        list: []
    },
    view: {
        loading: false,
        error: null,
        showModal: false,
        viewUpdated: false,
        editMode: false,
        view: {
            _id: null,
            view_name: null,
            createdAt: null,
            updatedAt: null,
            groups: null,
            members: null,
            orgId: null,
            privacy: null,
            filter: {
                templates: [],
                filters: [],
                columns: [],
                tasks: [],
                fields: [],
            },
            user: null,
        }
    }
};
const checklistsSlice = createSlice({
    name: "checklists",
    initialState,
    reducers: {
        setTemplates: (state, action: PayloadAction<{ data: any }>) => {
            state.templates.loading = false;
            state.templates.error = null;
            state.templates.data = action.payload.data;
        },
        setChecklists: (state, action: PayloadAction<{ data: any, totalCount: number, totalPages: number }>) => {
            state.checklists.data = action.payload.data;
            state.checklists.loading = false;
            state.checklists.error = null;
            state.checklists.totalCount = action.payload.totalCount;
            state.checklists.totalPages = action.payload.totalPages;
        },

        setActiveChecklists: (state, action: PayloadAction<{ data: any }>) => {
            state.checklists.loading = false;
            state.checklists.error = null;
            state.checklists.data = action.payload.data;
        },

        setRecentlyCompleted: (state, action: PayloadAction<{ data: any }>) => {
            state.checklists.loading = false;
            state.checklists.error = null;
            state.checklists.data = action.payload.data;
        },

        setOverDue: (state, action: PayloadAction<{ data: any }>) => {
            state.checklists.loading = false;
            state.checklists.error = null;
            state.checklists.data = action.payload.data;
        },

        setAssignedToMe: (state, action: PayloadAction<{ data: any }>) => {
            state.checklists.loading = false;
            state.checklists.error = null;
            state.checklists.data = action.payload.data;
        },
        setChecklistLoading: (state) => {
            state.checklists.loading = true;
        },
        errorChecklists: (state, action: PayloadAction<{ message: string }>) => {
            state.checklists.data = [];
            state.checklists.loading = false;
            state.checklists.error = action.payload.message;
        },
        setTemplateFilter: (state, action: PayloadAction<{ templates: any }>) => {
            state.view.view.filter.templates = action.payload.templates;
            state.view.viewUpdated = true;
        },
        setColumnFilter: (state, action: PayloadAction<{ columns: any }>) => {
            state.view.view.filter.columns = action.payload.columns;
            state.view.viewUpdated = true;
        },
        setTasksFilter: (state, action: PayloadAction<{ tasks: any }>) => {
            state.view.view.filter.tasks = action.payload.tasks;
            state.view.viewUpdated = true;
        },
        setFieldsFilter: (state, action: PayloadAction<{ fields: any }>) => {
            state.view.view.filter.fields = action.payload.fields;
            state.view.viewUpdated = true;
        },
        setFiltersFilter: (state, action: PayloadAction<{ filters: any }>) => {
            state.view.view.filter.filters = action.payload.filters;
            state.view.viewUpdated = true;
        },
        setViews: (state, action: PayloadAction<{ data: any }>) => {
            state.views.loading = false;
            state.views.error = null;
            state.views.list = action.payload.data
        },
        setView: (state, action: PayloadAction<{ view: any, data?: any, isEdit: boolean }>) => {
            state.view.loading = false;
            state.view.error = null;
            state.view.showModal = action.payload.isEdit;
            state.view.editMode = action.payload.isEdit;
            state.view.viewUpdated = false;
            state.view.view = action.payload.view;

            const index = state.views.list.findIndex(
                (v) => v._id === action.payload.view._id
            );
            
            if (index > -1) {
                state.views.list[index] = action.payload.view;
            }
            else {
                state.views.list.push(action.payload.view);
            }
            state.checklists.loading = false;
            state.checklists.data = action.payload.data ? action.payload.data : state.checklists.data;

        },
        setDeleteView: (state, action: PayloadAction<{ view_id: string, }>) => {
            state.view.loading = false;
            const tempViews = state.views.list.filter(v => v._id !== action.payload.view_id);
            state.views.list = tempViews;
            if (action.payload.view_id === state.view.view._id) {
                state.view = initialState.view;
            }

        },

        setChecklistAssignees: (state, action: PayloadAction<{ message: any }>) => {
            state.checklist.loading = false;
            state.checklist.error = null;
            state.checklist.success = action.payload.message
        },
        checklistClearSuccess: (state) => {
            state.checklist.success = null;
        },
        setLoadingAction: (state) => {
            state.view.loading = true;
        },
        clearViewsSuccessAction: (state) => {
            state.views.success = null;
        },
        updateView: (state, action: PayloadAction<{ view: any }>) => {
            state.views.loading = false;
            state.views.error = null;
            state.view.viewUpdated = true;
            state.view.view = action.payload.view;
        },
        setViewEmpty: (state) => {
            state.view = initialState.view;
            state.checklists.loading = true;
        },

        showHideModal: (state, action: PayloadAction<{ value: boolean }>) => {
            state.view.showModal = action.payload.value;
        },
        updateViewTouched: (state, action: PayloadAction<{ value: boolean }>) => {
            state.view.viewUpdated = action.payload.value;
        },
        setEditMode: (state, action: PayloadAction<{ value: boolean }>) => {
            state.view.editMode = action.payload.value;
        },
        setColumns: (state, action) => {
            state.columns = action.payload;
        },
        addColumn: (state, action) => {
            state.columns.push(action.payload)
        },
        removeColumn: () => {

        }
    }
});

export const {
    setChecklists,
    setTemplates,
    setActiveChecklists,
    setRecentlyCompleted,
    setOverDue,
    setAssignedToMe,
    setLoadingAction,
    errorChecklists,
    setChecklistLoading,
    showHideModal,
    setViews,
    setView,
    updateView,
    setViewEmpty,
    updateViewTouched,
    setTemplateFilter,
    setColumnFilter,
    setFiltersFilter,
    setChecklistAssignees,
    checklistClearSuccess,
    clearViewsSuccessAction,
    setEditMode,
    setDeleteView,
    setTasksFilter,
    setFieldsFilter,
    setColumns,
    addColumn,
    removeColumn,
} = checklistsSlice.actions;

export default checklistsSlice.reducer;