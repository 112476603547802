import { template, folder ,folderAssignee} from "../types";
export const TEMPLATE_ERROR = 'TEMPLATE_ERROR';
export const TEMPLATE_LOADING = 'TEMPLATE_LOADING';
export const TEMPLATES = 'TEMPLATES';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS';
export const FOLDER_SUCCESS = 'FOLDER_SUCCESS';
export const TEMPLATE_CLEAR_SUCCESS = "TEMPLATE_CLEAR_SUCCESS";
export const TEMPLATE_DELETE = 'TEMPLATE_DELETE';
export const TEMPLATE_CLEAR_ERROR = 'TEMPLATE_CLEAR_ERROR';
export const VIEW_SINGLE_TEMPLATE = 'SINGLE_TEMPLATE';
export const ASSIGN_MEMBER = 'ASSIGN_MEMBER';
export const RETRACT_MEMBER = 'RETRACT_MEMBER';
export const SCHEDULE_TASK_TEMPLATE = 'SCHEDULE_TASK_TEMPLATE';
export const ADD_FOLDER = 'ADD_FOLDER';
export const FOLDER = 'FOLDER';
export const ONEFOLDER_DELETE = 'ONEFOLDER_DELETE';
export const ASSIGNEEFOLDER_DELETE = 'ASSIGNEEFOLDER_DELETE';
export const ADD_FOLDER_ASSIGNEE = 'ADD_FOLDER_ASSIGNEE';
export const HOME_FOLDER ='HOME_FOLDER'
export const CLEAR_SINGLE_TEMPLATE_DATA = 'CLEAR_SINGLE_TEMPLATE_DATA';

export interface TemplateLoadingAction {
	type: typeof TEMPLATE_LOADING,
}

export interface TemplatesAction {
	type: typeof TEMPLATES,
	payload: { templates: any }

}
export interface DeleteTemplateAction {
	type: typeof TEMPLATE_DELETE,
	payload: { templateId: string }
}

export interface TemplateErrorAction {
	type: typeof TEMPLATE_ERROR,
	payload: { message: string }
}

export interface AssignTemplateAction {
	type: typeof ASSIGN_MEMBER,
	payload: {
		templateId: string,
		assignees: any
	}
}

export interface RetractTemplateAction {
	type: typeof RETRACT_MEMBER,
	payload: {
		templateId: string,
		assignees: any
	}
}

export interface AddTemplateAction {
	type: typeof ADD_TEMPLATE,
	payload: { template: template }
}

export interface AddFolderAction {
	type: typeof ADD_FOLDER,
	payload: { folder: folder }
}
export interface addFolderAssigneeAction {
	type: typeof ADD_FOLDER_ASSIGNEE,
	payload: { folderAssignee: folderAssignee }
}
export interface deleteAssigneeFolderAction {
	type: typeof ASSIGNEEFOLDER_DELETE,
	payload: { assigneeId: string }
	}
export interface AddTemplateSuccessAction {
	type: typeof TEMPLATE_SUCCESS,
	payload: { message: string }
}
export interface updateFolderAction {
	type: typeof FOLDER_SUCCESS,
	payload: { folder: folder }
}
export interface TemplateClearErrorAction {
	type: typeof TEMPLATE_CLEAR_ERROR,
}

export interface TemplateClearSuccessAction {
	type: typeof TEMPLATE_CLEAR_SUCCESS,
}

export interface viewSingleTemplateAction {
	type: typeof VIEW_SINGLE_TEMPLATE,
	payload: { template: any }
}
export interface clearSingleTemplateDataAction {
	type: typeof CLEAR_SINGLE_TEMPLATE_DATA,
}

export interface FolderAction {
	type: typeof FOLDER,
	payload: { folder: any }
}
export interface viewHomeFolderAction {
	type: typeof HOME_FOLDER,
	payload: { folders: any }
	
}
export interface DeleteOneFolderAction {
	type: typeof ONEFOLDER_DELETE,
	payload: { folderId: string }
}
export interface deleteAssigneeFolderAction {
	type: typeof ASSIGNEEFOLDER_DELETE,
	
}
export type TemplateActionTypes = TemplatesAction | AssignTemplateAction | RetractTemplateAction | TemplateClearErrorAction | TemplateLoadingAction | TemplateErrorAction | DeleteTemplateAction | clearSingleTemplateDataAction
	| AddTemplateAction | AddTemplateSuccessAction | TemplateClearSuccessAction | viewSingleTemplateAction | AddFolderAction | FolderAction | DeleteOneFolderAction |updateFolderAction |addFolderAssigneeAction|viewHomeFolderAction |deleteAssigneeFolderAction;
