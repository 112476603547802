export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const EMAIL_VALIDATION = 'EMAIL_VALIDATION';
export const SET_TOKEN = 'SET_TOKEN';

export type userInfo = {
	id: string,
	email: string,
	full_name: string,
	language: string
}
export interface LoginAction {
	type: typeof LOGIN,
	payload: {
		userInfo: userInfo,
		token: string
	}
}

export interface LogoutAction {
	type: typeof LOGOUT
}

export interface LoginErrorAction {
	type: typeof LOGIN_ERROR,
	payload: {
		message: string
	}
}

export interface LoginEmailFieldAction {
	type: typeof EMAIL_VALIDATION,
	payload: {
		message: string
	}
}
export interface LoginLoadingAction {
	type: typeof LOGIN_LOADING,
}

export interface LoginSetToken {
	type: typeof SET_TOKEN,
	payload: {
		token: string
	}
}

export type LoginActionTypes = LoginSetToken | LoginAction | LogoutAction | LoginErrorAction | LoginLoadingAction | LoginEmailFieldAction;