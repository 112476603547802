import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FOLDER, INITIAL_STATE } from "./types";

const initialState: INITIAL_STATE = {
	list: {
		loading: false,
		error: null,
		data: [],
	},
	folders: {
		loading: false,
		error: null,
		data: [],
	},
	folder: {
		loading: false,
		error: null,
		data: null,
	},
	orgFolders: {
		loading: false,
		error: null,
		data: [],
	},
};

const templatesSlice = createSlice({
	name: "templates",
	initialState,
	reducers: {
		getFolders: (state, action: PayloadAction<{ folderId: string }>) => {
			const temp = state.folders.data.find(
				(el) => el.folder === action.payload.folderId
			);
			state.folders.loading = temp && temp.list ? false : true;
			state.folders.error = null;
		},
		setFolders: (
			state,
			action: PayloadAction<{
				folderId: string;
				data: any;
				structure: any;
			}>
		) => {
			state.folders.loading = false;
			state.folders.error = null;
			const index = state.folders.data.findIndex(
				(el) => el.folder === action.payload.folderId
			);
			if (index > -1)
				state.folders.data[index] = {
					folder: action.payload.folderId,
					list: action.payload.data,
					structure: action.payload.structure,
				};
			else
				state.folders.data.push({
					folder: action.payload.folderId,
					list: action.payload.data,
					structure: action.payload.structure,
				});
		},
		errorFolders: (
			state,
			action: PayloadAction<{ folderId: string; message }>
		) => {
			state.folders.loading = false;
			state.folders.error = action.payload.message;
		},
		getList: (state, action: PayloadAction<{ folderId: string }>) => {
			const temp = state.list.data.find(
				(el) => el.folder === action.payload.folderId
			);
			state.list.loading = temp && temp.list ? false : true;
			state.list.error = null;
		},
		setList: (
			state,
			action: PayloadAction<{ folderId: string; data: any }>
		) => {
			state.list.loading = false;
			state.list.error = null;
			const index = state.list.data.findIndex(
				(el) => el.folder === action.payload.folderId
			);
			if (index > -1)
				state.list.data[index] = {
					folder: action.payload.folderId,
					list: action.payload.data,
				};
			else
				state.list.data.push({
					folder: action.payload.folderId,
					list: action.payload.data,
				});
		},
		errorList: (state, action: PayloadAction<string>) => {
			state.list.loading = false;
			state.list.error = action.payload;
		},
		getFolder: (state) => {
			state.folder.loading = true;
			state.folder.error = null;
		},
		setFolder: (state, action: PayloadAction<FOLDER>) => {
			state.folder.data = action.payload;
			state.folder.loading = false;
			state.folder.error = null;
		},
		errorFolder: (state, action: PayloadAction<string>) => {
			state.folder.loading = false;
			state.folder.error = action.payload;
		},
		getOrgFolders: (state, action: PayloadAction<{ orgId: string }>) => {
			const temp = state.orgFolders.data.find(
				(el) => el.org === action.payload.orgId
			);
			state.orgFolders.loading = temp?.list.length > 0 ? false : true;
			state.orgFolders.error = null;
		},
		setOrgFolders: (
			state,
			action: PayloadAction<{ orgId: string; data: any }>
		) => {
			state.orgFolders.loading = false;
			state.orgFolders.error = null;
			const index = state.orgFolders.data.findIndex(
				(el) => el.org === action.payload.orgId
			);
			if (index > -1)
				state.orgFolders.data[index] = {
					org: action.payload.orgId,
					list: action.payload.data,
				};
			else
				state.orgFolders.data.push({
					org: action.payload.orgId,
					list: action.payload.data,
				});
		},
		errorOrgFolders: (state, action: PayloadAction<string>) => {
			state.orgFolders.loading = false;
			state.orgFolders.error = action.payload;
		},
		updateArchiveStatus: (
			state,
			action: PayloadAction<{
				folderId: string;
				templateId: string;
				status: boolean;
			}>
		) => {
			const { status, templateId, folderId } = action.payload;
			const folderIndex = state.list.data.findIndex(
				(el) => el.folder === folderId
			);
			if (folderIndex > -1) {
				const templateIndex = state.list.data[
					folderIndex
				].list.findIndex((el) => el._id === templateId);
				state.list.data[folderIndex].list[
					templateIndex
				].archive = status;
			}
		},
		updatePrivacy: (
			state,
			action: PayloadAction<{
				folderId: string;
				templateId: string;
				status: string;
			}>
		) => {
			const { status, templateId, folderId } = action.payload;
			const folderIndex = state.list.data.findIndex(
				(el) => el.folder === folderId
			);
			if (folderIndex > -1) {
				const templateIndex = state.list.data[
					folderIndex
				].list.findIndex((el) => el._id === templateId);
				state.list.data[folderIndex].list[
					templateIndex
				].privacy = status;
			}
		},
		updateShareRunPermission: (
			state,
			action: PayloadAction<{
				folderId: string;
				templateId: string;
				type: "run" | "share";
				permission: string;
			}>
		) => {
			const { permission, type, templateId, folderId } = action.payload;
			const folderIndex = state.list.data.findIndex(
				(el) => el.folder === folderId
			);
			if (folderIndex > -1) {
				const templateIndex = state.list.data[
					folderIndex
				].list.findIndex((el) => el._id === templateId);
				state.list.data[folderIndex].list[templateIndex][
					`${type}Permission`
				] = permission;
			}
		},
	},
});

export const {
	getList,
	setList,
	errorList,
	getFolder,
	setFolder,
	errorFolder,
	getFolders,
	setFolders,
	errorFolders,
	getOrgFolders,
	setOrgFolders,
	errorOrgFolders,
	updateArchiveStatus,
	updatePrivacy,
	updateShareRunPermission,
} = templatesSlice.actions;

export default templatesSlice.reducer;
