import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./types";

const editTemplatesSlice = createSlice({
	name: "editTemplate",
	initialState,
	reducers: {
		getTemplate: (state) => {
			state.template.loading = true;
		},
		setTemplate: (state, action: PayloadAction<{ template: any }>) => {
			state.template.loading = false;
			state.template.error = null;
			state.template.data = action.payload.template;

			state.currentTask = state.template.data.tasks[0]._id;
			state.activeTask = state.template.data.tasks.find(
				(el) => el._id === state.currentTask
			);
		},
		setDefaultTemplate: (state, action: PayloadAction<{ template: any }>) => {
			state.defaultTemplate.loading = false;
			state.defaultTemplate.error = null;
			state.defaultTemplate.data = action.payload.template;

			state.currentTask = state.defaultTemplate.data.tasks[0]._id;
			state.activeTask = state.defaultTemplate.data.tasks.find(
				(el) => el._id === state.currentTask
			);
		},
		setTemplateTitle: (state, action: PayloadAction<{ title: string }>) => {
			state.template.data.title = action.payload.title;
		},
		setTemplateDescription: (
			state,
			action: PayloadAction<{ description: string }>
		) => {
			state.template.data.description = action.payload.description;
		},
		setTemplatePrivacy: (
			state,
			action: PayloadAction<{ privacy: string }>
		) => {
			state.template.data.privacy = action.payload.privacy;
		},
		setTaskUpdateRequest: (
			state,
			action: PayloadAction<{ value: boolean }>
		) => {
			state.loading = action.payload.value;
		},
		addTask: (state, action: PayloadAction<{ task: any }>) => {
			state.template.data?.tasks.push(action.payload.task);
			state.currentTask = action.payload.task._id;
		},
		setTaskStop: (state, action: PayloadAction<{ value: boolean }>) => {
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[index].isStopped =
					action.payload.value;
			}

			state.loading = false;
		},
		deleteTask: (state, action: PayloadAction<{ taskId: string }>) => {
			const { taskId } = action.payload;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === taskId
			);
			const filteredTasks = state.template.data.tasks.filter(
				(el) => el._id !== taskId
			);
			state.template.data.tasks = filteredTasks;
			if (taskId === state.currentTask) {
				if (index > 0)
					state.currentTask =
						state.template.data.tasks[index - 1]._id;
				else state.currentTask = state.template.data.tasks[0]._id;
			}
		},
		setCurrentTask: (state, action: PayloadAction<{ taskId: string }>) => {
			state.currentTask = action.payload.taskId;
			state.activeTask = state.template.data.tasks.find(
				(el) => el._id === action.payload.taskId
			);
		},
		switchTask: (state, action: PayloadAction<{from: number, to: number}>) => {
			const { from, to } = action.payload;
			const fromTask = state.template.data.tasks[from];
			const tasks = state.template.data.tasks.filter((e: any) => e._id !== fromTask._id).map(e => e);
			const front = tasks.slice(0, to);
			const back = tasks.splice(to, state.template.data.tasks.length);
			state.template.data.tasks = [...front, fromTask, ...back]
		},
		updateCurrentTask: (
			state,
			action: PayloadAction<{ type: string; checked: boolean, id?: string, responseData?: object, fileAttach?: boolean }>
		) => {
			const { type, checked, id, responseData, fileAttach } = action.payload;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				if (fileAttach) {
					state.template.data.tasks[index] = responseData

					if (['members', 'groups'].includes(type)) {
						const data = checked ?
							[...state.template.data.tasks[index].permission[type], id] :
							state.template.data.tasks[index].permission[type].filter((el: any) => el !== id);
						state.template.data.tasks[index].permission[type] = data;
						state.activeTask.permission[type] = data;
					} else {
						if (['members', 'groups', 'normal'].includes(type)) {
							const data = checked ?
								[...state.template.data.tasks[index].permission[type], id] :
								state.template.data.tasks[index].permission[type].filter((el: any) => el !== id);
							state.template.data.tasks[index].permission[type] = data;
							state.activeTask.permission[type] = data;
						} else {
							state.activeTask.permission[type] = checked;
							state.template.data.tasks[index].permission[type] = checked;
						}
					}
				}
			}
		},
		setElements: (state, action: PayloadAction<{ elements: any }>) => {
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[index].elements =
					action.payload.elements;
			}
		},
		setApprovalTasks: (
			state,
			action: PayloadAction<{ approvalTasks: any }>
		) => {
			const { approvalTasks } = action.payload;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[index].approvalTasks = approvalTasks;
			}
		},
		setDurationTasks: (
			state,
			action: PayloadAction<{ duration: any }>
		) => {
			const { duration } = action.payload;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[index].duration = duration;
			}
		},
		setTaskHeading: (state, action: PayloadAction<{ heading: string }>) => {
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[index].heading =
					action.payload.heading;
			}
		},
		setTaskDueDate: (
			state,
			action: PayloadAction<{ dynamicDateCount: any; dynamicSettings }>
		) => {
			const { dynamicDateCount, dynamicSettings } = action.payload;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);
			if (index !== null) {
				state.template.data.tasks[
					index
				].dynamicDateCount = dynamicDateCount;
				state.template.data.tasks[
					index
				].dynamicSettings = dynamicSettings;
			}

			state.loading = false;
		},
		setTaskStepNumber: (
			state,
			action: PayloadAction<{ stepNumber: number }>
		) => {
			const { tasks } = state.template.data;
			const index = state.template.data.tasks.findIndex(
				(el) => el._id === state.currentTask
			);

			if (index !== null) {
				if (action.payload.stepNumber === -1 && index > 0) {
					if (index > 0) {
						const temp = tasks[index];
						state.template.data.tasks[index] = tasks[index - 1];
						state.template.data.tasks[index - 1] = temp;
					}
				} else {
					const temp = tasks[index];
					if (index + 1 < state.template.data.tasks.length) {
						state.template.data.tasks[index] = tasks[index + 1];
						state.template.data.tasks[index + 1] = temp;
					}
				}
			}
		},
	},
});

export const {
	getTemplate,
	setTaskUpdateRequest,
	setTemplate,
	setDefaultTemplate,
	setTemplateTitle,
	setTemplateDescription,
	setCurrentTask,
	setTaskStepNumber,
	setTaskDueDate,
	setElements,
	setTaskHeading,
	addTask,
	deleteTask,
	setApprovalTasks,
	setTemplatePrivacy,
	setTaskStop,
	updateCurrentTask,
	switchTask,
	setDurationTasks,
} = editTemplatesSlice.actions;

export default editTemplatesSlice.reducer;
