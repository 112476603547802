// Forgot Reducer
import { ForgotActionTypes, FORGOTPASSWORD_ERROR, FORGOTPASSWORD_LOADING, FORGOTPASSWORD_SUCCESS , FORGOTPASSWORD_CLEAR_ERROR, FORGOTPASSWORD_CLEAR_SUCCESS} from "../types/ForgotPassword";
interface DefaultStateI {
	error?: string,
	loading?: boolean
	success?: boolean
}
const forgotReducerinitialState: DefaultStateI = {
	error: "",
	loading: false
};

const forgotReducer = (state: DefaultStateI = forgotReducerinitialState, action: ForgotActionTypes): DefaultStateI => {
	switch (action.type) {
		case FORGOTPASSWORD_SUCCESS: return { ...state, error: "", loading: false, success: true }
		case FORGOTPASSWORD_LOADING: return { loading: true }
		case FORGOTPASSWORD_ERROR: return { ...state, error: action.payload.message, loading: false }
		case FORGOTPASSWORD_CLEAR_ERROR: return { ...state, error: '', loading: false }
		case FORGOTPASSWORD_CLEAR_SUCCESS: return { ...state, success: false }

	}
	return state;
};
export { forgotReducer }