
import { DISPLAY_MEMBERS, ADD_MEMBER, MEMBERS_ERROR, MEMBERS_LOADING, MEMBERS_SUCCESS, MEMBERS_CLEAR_SUCCESS, MEMBERS_CLEAR_ERROR } from "../types/Schedule";
import { ScheduleActionTypes } from "../types/Schedule";

interface DefaultStateI {
	schedule: any,
	error?: string,
	loading?: boolean,
	success?: boolean,
}
const scheduleReducerinitialState: DefaultStateI = {
	schedule: {},
	error: "",
	loading: false,
	success: false
};

const scheduleReducer = (state: DefaultStateI = scheduleReducerinitialState, action: ScheduleActionTypes): DefaultStateI => {

	switch (action.type) {
		case DISPLAY_MEMBERS: return { ...state, schedule: action.payload.schedules, loading: false }
		case ADD_MEMBER: return { ...state, success: true, schedule: action.payload.schedules, loading: false }
		case MEMBERS_SUCCESS: return { ...state, success: true, loading: false }
		case MEMBERS_CLEAR_SUCCESS: return { ...state, success: false, loading: false }
		case MEMBERS_CLEAR_ERROR: return { ...state, error: "", loading: false }
		case MEMBERS_LOADING: return { ...state, loading: true }
		case MEMBERS_ERROR: return { ...state, error: action.payload.message, loading: false }
	}
	return state;
};
export { scheduleReducer }