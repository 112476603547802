import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type ORG = {
	id: String;
	name: String;
};

type TEMPLATE = {
	id: String;
	title: String;
	org: ORG;
};

export interface INITIAL_STATE {
	template: null | TEMPLATE;
	loading: Boolean;
}

const initialState: INITIAL_STATE = {
	template: null,
	loading: false,
};

const templateSplice = createSlice({
	name: "publicTemplate",
	initialState,
	reducers: {
		reset: (state) => {
			state.template = null;
		},
		get: (state) => {
			state.loading = true;
		},
		setTemplateData: (
			state,
			action: PayloadAction<{ id: String; title: String, org: ORG }>
		) => {
			const { id, title, org } = action.payload;
			state.template = {
				id: id,
				title: title,
				org: org,
			};
			state.loading = false;
		},
	},
});

export const { setTemplateData, reset, get } = templateSplice.actions;

export default templateSplice.reducer;
