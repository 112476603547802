import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ORG = {
	id: String;
	name: String;
};

export interface INITIAL_STATE {
	organization: null | ORG;
}

const initialState: INITIAL_STATE = {
	organization: null,
};

const shareLinkSplice = createSlice({
	name: "shareLink",
	initialState,
	reducers: {
		reset: (state) => {
			state.organization = null;
		},
		setOrg: (
			state,
			action: PayloadAction<{ id: String; name: String }>
		) => {
			const { id, name } = action.payload;
			state.organization = {
				id: id,
				name: name,
			};
		},
	},
});

export const { setOrg, reset } = shareLinkSplice.actions;

export default shareLinkSplice.reducer;
