import {CustomViewActionTypes} from '../types/CustomView'
import {SAVE_VIEW,LOADING,SUCCESS,CHECKLISTFILTER,SHOW_VIEW_MODEL,SAVE_GROUP,DELETE_VIEW,TEMPLATE_ID} from '../types/CustomView'


interface DefaultStateI {
    view:any;
	success?: boolean,
	error?: string,
	loading?: boolean,
	view_model?: boolean,
	filter?:any,
	groups?:any,
	templates?:any
}

const CustomViewReducerinitialState: DefaultStateI = {
	view: [],
	error: "",
	loading: false,
	success: false,
	filter:[],
	view_model:false,
	groups:[],
	templates:[]
};
const Custom_viewReducer = (state: DefaultStateI = CustomViewReducerinitialState, action: CustomViewActionTypes): DefaultStateI => {
    switch (action.type) {
        case SAVE_VIEW :return {...state,view:action.payload.data}
		case LOADING :return {...state,loading:action.payload.loading}
		case SUCCESS :return {...state,loading:action.payload.loading,success:action.payload.success,view_model:false}
		case CHECKLISTFILTER :return {...state,filter:action.payload.data}
		case SHOW_VIEW_MODEL :return {...state,view_model:action.payload.view_model}
		case SAVE_GROUP :return {...state,groups:action.payload.data}
		case TEMPLATE_ID :return {...state,templates:action.payload.data}
		case DELETE_VIEW:
		let views = state.view
		views = views.filter(data=>data._id !== action.payload.id)	
		return {...state,view:views}

	}

	return state;

}
export {Custom_viewReducer}