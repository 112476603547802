//this is where we combine reducers and export. This application reducer will be used by store.

import { combineReducers } from "redux";
import { loginReducer } from "./login";
import { registerReducer } from "./register";
import { checklistReducer } from "./checklist";
import { templateReducer } from "./template";
import { profileReducer } from "./profile";
import { Custom_viewReducer } from "./customViewReducer";
import { membersReducer } from "./members";
import { organizationReducer } from "./organization";
import { groupReducer } from "./groups";
import { scheduleReducer } from "./Schedule";
import { activityReducer } from "./activity";
import { forgotReducer } from "./forgotPassword";
import {adminReducer } from "./admin-panel"

import templates from "../../modules/Templates/reducers";
import members from "../../modules/Members/reducers";
import comments from "../../modules/Comments/reducers";
import inbox from "../../modules/Inbox/reducers";
import checklists from "../../modules/checklists/reducers";
import permissions from "../../modules/Permissions/reducers";
import editTemplate from "../../modules/EditTemplate/reducers";
import shareLink from "../../modules/ShareLink/reducers";
import publicTemplate from "../../modules/LibraryTemplateView/reducers";

export const applicationReducer = combineReducers({
  loginReducer: loginReducer,
  registerReducer: registerReducer,
  checklistReducer: checklistReducer,
  templateReducer: templateReducer,
  activityReducer: activityReducer,
  profileReducer: profileReducer,
  membersReducer: membersReducer,
  myView: Custom_viewReducer,
  organizationReducer: organizationReducer,
  memberReducer: membersReducer,
  scheduleReducer: scheduleReducer,
  groupReducer: groupReducer,
  forgotReducer: forgotReducer,
  adminReducer: adminReducer,

  checklists,
  templates,
  members,
  comments,
  inbox,
  permissions,
  editTemplate,
  shareLink,
  publicTemplate,
});
