import { notification } from "antd";

export const notify = (type: string, message: string) => {
  notification[type]({
    message: message,
    placement: "bottomRight",
    duration: 2
  });
};

export const notificationType = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error'
}