import {
  TEMPLATES,
  ADD_TEMPLATE,
  TEMPLATE_ERROR,
  ASSIGN_MEMBER,
  RETRACT_MEMBER,
  TEMPLATE_LOADING,
  TEMPLATE_DELETE,
  TEMPLATE_CLEAR_ERROR,
  TEMPLATE_SUCCESS,
  TEMPLATE_CLEAR_SUCCESS,
  VIEW_SINGLE_TEMPLATE,
  FOLDER,
  ADD_FOLDER,
  ONEFOLDER_DELETE,
  FOLDER_SUCCESS,
  HOME_FOLDER,
  ADD_FOLDER_ASSIGNEE,
  ASSIGNEEFOLDER_DELETE,
  CLEAR_SINGLE_TEMPLATE_DATA
} from "../types/Template";
import { TemplateActionTypes } from "../types/Template";

interface DefaultStateI {
  templates?: any;
  singleTemplate?: any;
  error?: string;
  loading?: boolean;
  success?: boolean;
  folder?: any;
  folders?: any;
  message?: string;
}
const templateReducerinitialState: DefaultStateI = {
  templates: [],
  singleTemplate: {},
  error: "",
  loading: false,
  success: false,
  folder: [],
  folders: [],
  message: ""
};

const templateReducer = (
  state: DefaultStateI = templateReducerinitialState,
  action: TemplateActionTypes
): DefaultStateI => {
  switch (action.type) {
    case TEMPLATES:
      return { ...state, templates: action.payload.templates, loading: false };
    case VIEW_SINGLE_TEMPLATE:
      return {
        ...state,
        singleTemplate: action.payload.template,
        loading: false,
      };
    case ADD_TEMPLATE:
      return {
        ...state,
        singleTemplate: action.payload.template,
        success: true,
        loading: false,
      };
    case TEMPLATE_LOADING:
      return { ...state, loading: true };
    case TEMPLATE_ERROR:
      return { ...state, error: action.payload.message, loading: false };
    case ASSIGN_MEMBER:
      return {
        ...state,
        success: true,
        templates: state.templates.map((template: any) => {
          if (template._id === action.payload.templateId) {
            template.assignees = action.payload.assignees;
            return template;
          }
          return template;
        }),
        singleTemplate: {
          ...state.singleTemplate,
          assignees: action.payload.assignees,
        },
      };
    case RETRACT_MEMBER:
      return {
        ...state,
        success: true,
        templates: state.templates.map((template: any) => {
          if (template._id === action.payload.templateId) {
            template.assignees = action.payload.assignees;
            return template;
          }
          return template;
        }),
        singleTemplate: {
          ...state.singleTemplate,
          assignees: action.payload.assignees,
        },
      };
    case TEMPLATE_CLEAR_ERROR:
      return { ...state, error: "", loading: false };
    case TEMPLATE_SUCCESS:
      return { ...state, error: "", success: true, loading: false };
    case TEMPLATE_CLEAR_SUCCESS:
      return { ...state, success: false, message: "" };
    case TEMPLATE_DELETE:
      return {
        ...state,
        success: true,
        message: "template deleted",
        templates: state.templates.filter(
          (template: any) => template.id !== action.payload.templateId
        ),
      };
    case FOLDER:
      return { ...state, folder: action.payload.folder, loading: false };
    case ADD_FOLDER: {
      return {
        ...state,
        folder: [...state.folder, action.payload.folder],
        loading: false,
        success: true,
      };
    }
    case ONEFOLDER_DELETE: {
      return {
        ...state,
        success: true,
        folder: state.folder.filter(
          (folder: any) => folder._id !== action.payload.folderId
        ),
      };
    }
    case FOLDER_SUCCESS:
      return {
        ...state,
        error: "",
        folder: action.payload.folder,
        loading: false,
      };
    case HOME_FOLDER:
      return {
        ...state,
        error: "",
        folders: action.payload.folders,
        success: true,
        loading: false,
      };
    case ADD_FOLDER_ASSIGNEE:
      return {
        ...state,
        folder: {
          ...state.folder,
          assignees: action.payload.folderAssignee.assignees,
        },
        error: "",
        success: true,
        loading: false,
      };
    case ASSIGNEEFOLDER_DELETE:
      return {
        ...state,
        success: true,
        folder: {
          ...state.folder, assignees: state.folder.assignees.filter(
            (folder: any) => folder._id !== action.payload.assigneeId
          )
        },
      };
    case CLEAR_SINGLE_TEMPLATE_DATA: return { ...state, singleTemplate: {} }
  }

  return state;
};
export { templateReducer };
