import { organization } from "../types";
export const ORGANIZATION_LOADING = 'ORGANIZATION_LOADING';
export const CREARE_ORGANIZATION = 'CREARE_ORGANIZATION';
export const ORGANIZATION_SUCCESS = 'ORGANIZATION_SUCCESS';
export const ORGANIZATION_CLEAR_SUCCESS = "ORGANIZATION_CLEAR_SUCCESS";
export const ORGANIZATION_CLEAR_ERROR = 'ORGANIZATION_CLEAR_ERROR';
export const ORGANIZATION_ERROR = 'ORGANIZATION_ERROR';
export const ORGANIZATIONS = 'ORGANIZATIONS';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const SET_DEFAULT_ORGANIZATION = 'SET_DEFAULT_ORGANIZATION';
export const GET_DEFAULT_ORGANIZATION = 'GET_DEFAULT_ORGANIZATION';
export const GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID';
export const CREARE_ORGANIZATION_GROUP = 'CREARE_ORGANIZATION_GROUP';
export const ORGANIZATION_GROUPS = 'ORGANIZATION_GROUPS';
export const UPDATE_ORGANIZATION_GROUP = 'UPDATE_ORGANIZATION_GROUP';
export const DELETE_ORGANIZATION_GROUP = 'DELETE_ORGANIZATION_GROUP';
export const SELECTED_GROUP = 'SELECTED_GROUP';

export interface OrganizationLoadingAction {
    type: typeof ORGANIZATION_LOADING,
}
export interface GetOrganizationsAction {
    type: typeof ORGANIZATIONS,
    payload: {
        organizations: any
    }
}
export interface CreateOrganizationAction {
    type: typeof CREARE_ORGANIZATION,
    payload: {
        organization: organization
    }
}
export interface DeleteOrganizationAction {
    type: typeof DELETE_ORGANIZATION,
    payload: {
        organizationId: string
    }
}
export interface AddOrganizationSuccessAction {
    type: typeof ORGANIZATION_SUCCESS,
    payload: {
        message: string
    }
}
export interface OrganizationErrorAction {
    type: typeof ORGANIZATION_ERROR,
    payload: {
        message: string
    }
}
export interface OrganizationClearErrorAction {
    type: typeof ORGANIZATION_CLEAR_ERROR,
}

export interface OrganizationClearSuccessAction {
    type: typeof ORGANIZATION_CLEAR_SUCCESS,
}
export interface DeleteOrganizationSuccessAction {
    type: typeof DELETE_ORGANIZATION_SUCCESS,
    payload: {
        message: boolean
    }
}
export interface SetDefaultOrganizationAction {
    type: typeof SET_DEFAULT_ORGANIZATION,
    payload: {
        organizationId: string
    }
}
export interface GetDefaultOrganizationAction {
    type: typeof GET_DEFAULT_ORGANIZATION,
    payload: {
        defaultOrganization: any
    }
}
export interface GetOrganizationByIdAction {
    type: typeof GET_ORGANIZATION_BY_ID,
    payload: {
        organizationById: any
    }
}
export interface CreateOrganizationGroupAction {
    type: typeof CREARE_ORGANIZATION_GROUP,
    payload: {
        groupName: string
    }
}
export interface GetOrganizationGroupsAction {
    type: typeof ORGANIZATION_GROUPS,
    payload: {
        organizationGroups: any
    }
}
export interface UpdateOrganizationGroupAction {
    type: typeof UPDATE_ORGANIZATION_GROUP,
    payload: {
        organizationGroup: any
    }
}
export interface DeleteOrganizationGroupAction {
    type: typeof DELETE_ORGANIZATION_GROUP,
    payload: {
        organizationGroupId: string
    }
}
export interface SelectedGroupAction {
    type: typeof SELECTED_GROUP,
    payload: {
        selectedGroupData: any
    }
}
export type OrganizationActionTypes = CreateOrganizationAction | GetOrganizationsAction | DeleteOrganizationAction | OrganizationLoadingAction | AddOrganizationSuccessAction |
    OrganizationErrorAction | OrganizationClearErrorAction | OrganizationClearSuccessAction | DeleteOrganizationSuccessAction | SetDefaultOrganizationAction | GetDefaultOrganizationAction | GetOrganizationByIdAction |
    CreateOrganizationGroupAction | GetOrganizationGroupsAction | UpdateOrganizationGroupAction | DeleteOrganizationGroupAction | SelectedGroupAction;