import enUS from "antd/es/locale/en_US";
import zh_HK from "antd/es/locale/zh_HK";
import fr_FR from "antd/es/locale/fr_FR";
import es_ES from "antd/es/locale/es_ES";
import it_IT from "antd/es/locale/it_IT";
import ar_EG from "antd/es/locale/ar_EG";
import pt_PT from "antd/es/locale/pt_PT";

export const locales: { [key: string]: any } = {
  enUS: enUS,
  zh_HK: zh_HK,
  it_IT: it_IT,
  es_ES: es_ES,
  fr_FR: fr_FR,
  ar_EG: ar_EG,
  pt_PT: pt_PT,
};

export const languages = [
  { label: "English", value: "enUS" },
  { label: "中文", value: "zh_HK" },
  { label: "Español", value: "es_ES" },
  { label: "Français", value: "fr_FR" },
  { label: "Italiano", value: "it_IT" },
  { label: "العربية", value: "ar_EG" },
  { label: "Portuguese", value: "pt_PT" },
];

export const getLocale = (language) => {
  const preferredLocale = languages.filter((item) => item.label === language);
  return preferredLocale[0]?.value ? preferredLocale[0].value : "enUS";
};
