
import { GroupActionTypes } from "../types/Groups";
import {
    GROUP_ERROR, GROUP, DELETE_MEMBER, CLEAR_GROUP_DATA, GROUP_LOADING, GROUP_CLEAR_ERROR, GROUP_SUCCESS,
    GROUP_CLEAR_SUCCESS, GROUPS, DELETE_GROUP_SUCCESS, UPDATE_GROUP_SUCCESS, ADD_MEMBER_TO_GROUP_ERROR, ADD_MEMBER_TO_GROUP_SUCCESS
} from "../types/Groups";

interface DefaultStateI {
    groups?: any, error?: string, loading?: boolean, success?: string, group?: any
}
const groupReducerinitialState: DefaultStateI = {
    groups: [], error: "", loading: false, success: "", group: []
};

const groupReducer = (state: DefaultStateI = groupReducerinitialState, action: GroupActionTypes): DefaultStateI => {
    switch (action.type) {
        case GROUPS: return { ...state, groups: action.payload.groups, loading: false }
        case GROUP: return { ...state, group: action.payload.group, loading: false, error: "", success: "" }
        case GROUP_LOADING: return { ...state, loading: true }
        case ADD_MEMBER_TO_GROUP_SUCCESS: return { ...state, loading: false, success: action.payload.message }
        case DELETE_MEMBER: return { ...state, loading: false, success: "Successfully Deleted" }
        case ADD_MEMBER_TO_GROUP_ERROR: return { ...state, loading: false, error: action.payload.message }
        case GROUP_ERROR: return { ...state, error: action.payload.message, loading: false }
        case GROUP_CLEAR_ERROR: return { ...state, error: "", loading: false }
        case GROUP_SUCCESS: return { ...state, error: "", success: action.payload.message, loading: false }
        case GROUP_CLEAR_SUCCESS: return { ...state, success: "", loading: false }
        case CLEAR_GROUP_DATA: return { ...state, success: "", loading: false, group: [], error: "" }
        case DELETE_GROUP_SUCCESS: return {
            ...state, success: action.payload.message, loading: false, error: "",
            groups: state.groups?.length > 0 && state?.groups?.data?.filter(
                (group: any) => group._id !== action.payload.id
            ),
        }
        case UPDATE_GROUP_SUCCESS: return { ...state, success: action.payload.message, loading: false, error: "", }
    }
    return state;
};
export { groupReducer }