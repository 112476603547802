// Store Configuration

import { applyMiddleware, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { applicationReducer } from "./reducers/applicationReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { appActions } from "./types/index";

export type appState = ReturnType<typeof applicationReducer>;
export const store = createStore(applicationReducer, composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<appState, appActions>)));


