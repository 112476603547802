export const PROFILE = 'PROFILE';
export const PROFILE_DATA = 'PROFILE_DATA';
export const PROFILE_URL_DATA = 'PROFILE_URL_DATA';
export const TEMPLATE_LOADING = 'TEMPLATE_LOADING';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_CLEAR_ERROR = 'PROFILE_CLEAR_ERROR';
export const PROFILE_IMG_DATA = 'PROFILE_IMG_DATA';
export const PROFILE_SUCCESS_MESSAGE = 'PROFILE_SUCCESS_MESSAGE';
export const CLEAR_PROFILE_SUCCESS_MESSAGE = 'CLEAR_PROFILE_SUCCESS_MESSAGE';
export interface newUser {
    email: string,
    full_name?: string,
    contact_number?: string,
    role?: string,
    time_zone?: string,
    password: string,
    occupation?: string,
    image?: string,
    emails: any
}

export interface ProfileAction {
    type: typeof PROFILE_DATA,
    payload: {
        profile_data: any
    }
}

export interface ImageAction {
    type: typeof PROFILE_IMG_DATA,
    payload: {
        image_data: any
    }
}

export interface ProfileUrlAction {
    type: typeof PROFILE_URL_DATA,
    payload: {
        profile_url_data: any
    }
}

export interface ProfileActionMain {
    type: typeof PROFILE,
    payload: {
        newUser: newUser
    }
}

export interface ProfileSuccessAction {
    type: typeof PROFILE_SUCCESS,
    payload: {
        update_data: any
    }
}

export interface ProfileErrorAction {
    type: typeof PROFILE_ERROR,
    payload: {
        message: string
    }
}

export interface ProfileSuccessMessage {
    type: typeof PROFILE_SUCCESS_MESSAGE,
}
export interface ClearprofileSuccessMessage {
    type: typeof CLEAR_PROFILE_SUCCESS_MESSAGE,
}

export interface ProfileLoadingAction {
    type: typeof PROFILE_LOADING,
}

export interface ProfileClearErrorAction {
    type: typeof PROFILE_CLEAR_ERROR,
}


export type ProfileActionTypes = ProfileAction | ProfileUrlAction | ProfileActionMain | ProfileErrorAction | ProfileLoadingAction | ProfileSuccessAction | ProfileClearErrorAction | ImageAction | ProfileSuccessMessage | ClearprofileSuccessMessage;