import { createSlice, PayloadAction } from '@reduxjs/toolkit';
 
interface INITIAL_STATE {
	loading: boolean;
	error: null | string;
	data: any;
}

const initialState: INITIAL_STATE = {
	loading: false,
	error: null,
	data: [],
};

const permissionsSplice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        getPermissions: (state, action: PayloadAction<{ templateId: string }>) => {
			const temp = state.data.find(
				(el) => el.template === action.payload.templateId
			);
			state.loading = temp && temp.list ? false : true;
			state.error = null;
		},
        setPermissions: (
			state,
			action: PayloadAction<{
				templateId: string;
				data: any;
			}>
		) => {
			state.loading = false;
			state.error = null;
			const index = state.data.findIndex(
				(el) => el.template === action.payload.templateId
			);
			if (index > -1)
				state.data[index] = {
					template: action.payload.templateId,
					list: action.payload.data,
				};
			else
				state.data.push({
					template: action.payload.templateId,
					list: action.payload.data,
				});
		},
        errorPermissions: (state, action: PayloadAction<{ message: string }>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
    }
})

export const { getPermissions, setPermissions, errorPermissions } = permissionsSplice.actions;

export default permissionsSplice.reducer;