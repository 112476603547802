export const FORGOTPASSWORD = 'FORGOTPASSWORD';
export const RESETPASSWORD = 'RESETPASSWORD';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS'
export const FORGOTPASSWORD_ERROR = 'FORGOTPASSWORD_ERROR';
export const FORGOTPASSWORD_LOADING = 'FORGOTPASSWORD_LOADING';
export const FORGOTPASSWORD_CLEAR_SUCCESS = 'FORGOTPASSWORD_CLEAR_SUCCESS';
export const FORGOTPASSWORD_CLEAR_ERROR = 'FORGOTPASSWORD_CLEAR_ERROR';


export interface userEmail { email: string }

export interface ForgotAction {
	type: typeof FORGOTPASSWORD_SUCCESS,
	payload: {
		message: string
	}
}


export interface ForgotPasswordClearAction {
	type: typeof FORGOTPASSWORD_CLEAR_SUCCESS
}

export interface ForgotErrorAction {
	type: typeof FORGOTPASSWORD_ERROR,
	payload: {
		message: string
	}
}

export interface ForgotLoadingAction {
	type: typeof FORGOTPASSWORD_LOADING,
}

  
  export interface ForgotPasswordClearErrorAction {
	type: typeof FORGOTPASSWORD_CLEAR_ERROR,
  }

export type ForgotActionTypes = ForgotAction | ForgotErrorAction | ForgotLoadingAction | ForgotPasswordClearErrorAction | ForgotPasswordClearAction;