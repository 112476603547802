export interface TEMPLATE {
  _id: string;
  title: string;
  description: string;
  privacy: string;
  archive?: boolean;
  folder?: any;
  orgId?: string;
  runPermission?: string;
  shareLink?: string;
  tasks: Array<any>;
  permissions?: Array<any>;
  subscriptions?: Array<any>;
  assignees?: Array<any>;
  path?: Array<{ _id: String; name: String }> | null;
}
const initialTemplate = {
  _id: "",
  title: "",
  description: "",
  privacy: "private",
  tasks: [],
};

interface INITIAL_STATE {
  template: {
    loading: boolean;
    error: string | null;
    data: TEMPLATE;
  };
  defaultTemplate: {
    loading: boolean;
    error: string | null;
    data: TEMPLATE;
  };
  users: any;
  currentTask: string | null;
  activeTask: any;
  loading: boolean;
  error: string | null;
}
export const initialState: INITIAL_STATE = {
  template: {
    loading: false,
    error: null,
    data: initialTemplate,
  },
  defaultTemplate: {
    loading: false,
    error: null,
    data: initialTemplate,
  },
  users: [],
  currentTask: null,
  activeTask: null,
  loading: false,
  error: null,
};
