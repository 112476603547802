export const MEMBERS_ERROR = 'MEMBERS_ERROR';
export const MEMBERS_SUCCESS = 'MEMBERS_SUCCESS';
export const MEMBERS_LOADING = 'MEMBERS_LOADING ';
export const DISPLAY_MEMBERS = 'DISPLAY_MEMBERS';
export const ADD_MEMBER = 'ADD_MEMBER';
export const MEMBERS_CLEAR_ERROR = 'MEMBERS_CLEAR_ERROR';
export const MEMBERS_CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const MEMBERS_DELETE = 'TEMPLATE_DELETE';

export interface MembersAction {
  type: typeof DISPLAY_MEMBERS,
  payload: {
    schedules: any
  }
}

export interface AddMemberAction {
	type: typeof ADD_MEMBER,
	payload: {
	  schedules: any
	}
  }

export interface MembersSuccessAction {
  type: typeof MEMBERS_SUCCESS
}
export interface DeleteMembarsAction {
	type: typeof MEMBERS_DELETE,
	payload: { schedules: any }
}
export interface MembersClearSuccessAction {
  type: typeof MEMBERS_CLEAR_SUCCESS
}

export interface MembersErrorAction {
  type: typeof MEMBERS_ERROR,
  payload: {
    message: string
  }
}

export interface MembersLoadingAction {
  type: typeof MEMBERS_LOADING,
}

export interface MembersClearErrorAction {
  type: typeof MEMBERS_CLEAR_ERROR,
}

export type ScheduleActionTypes = MembersAction | AddMemberAction | MembersClearErrorAction | MembersClearSuccessAction | MembersErrorAction | MembersSuccessAction | MembersLoadingAction | DeleteMembarsAction;