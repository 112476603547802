// Login Reducer

import { LOGIN, LOGIN_ERROR, LOGOUT, LOGIN_LOADING, EMAIL_VALIDATION, SET_TOKEN } from "../types/Login";
import { LoginActionTypes, userInfo } from "../types/Login";
import Cookies from 'js-cookie';
interface DefaultStateI {
	userInfo?: userInfo,
	token?: string,
	registration?: boolean,
	error?: string,
	loading?: boolean,
	message?: string
}
const loginReducerinitialState: DefaultStateI = {
	userInfo: JSON.parse(localStorage.getItem('userInfo') as string),
	token: Cookies.get('token'),
	registration: false,
	error: "",
	loading: false
};

const loginReducer = (state: DefaultStateI = loginReducerinitialState, action: LoginActionTypes): DefaultStateI => {
	switch (action.type) {
		case LOGIN: localStorage.setItem('userInfo',JSON.stringify(action.payload.userInfo)); return { ...state, userInfo: action.payload.userInfo, token: action.payload.token, loading: false }
		case LOGIN_LOADING: return { loading: true }
		case LOGOUT: Cookies.remove('token'); localStorage.removeItem('userInfo'); return { token: "" }
		case LOGIN_ERROR: return { ...state, error: action.payload.message, loading: false }
        case EMAIL_VALIDATION: return {...state, error: action.payload.message,}
        case SET_TOKEN: return {...state, token: action.payload.token,}
	}
	return state;
};
export { loginReducer }