export const GROUP_LOADING = 'GROUP_LOADING';
export const CREATE_GROUP = 'CREATE_GROUP';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';
export const GROUP_ERROR = 'GROUP_ERROR';
export const GROUPS = 'GROUPS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const GROUP = 'GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const ADD_MEMBER_TO_GROUP_SUCCESS = 'ADD_MEMBER_TO_GROUP_SUCCESS';
export const ADD_MEMBER_TO_GROUP_ERROR = 'ADD_MEMBER_TO_GROUP_ERROR';

export const CLEAR_GROUP_DATA = 'CLEAR_GROUP_DATA';
export const GROUP_CLEAR_SUCCESS = "GROUP_CLEAR_SUCCESS";
export const GROUP_CLEAR_ERROR = 'GROUP_CLEAR_ERROR';


export interface group { name: string, }
export interface groupId { _id: string, }
export interface updateGroup { _id: string, name: string, image?: string }

export interface GroupLoadingAction {
    type: typeof GROUP_LOADING,
}
export interface GetGroupsAction {
    type: typeof GROUPS,
    payload: {
        groups: any
    }
}
export interface GetGroupAction {
    type: typeof GROUP,
    payload: {
        group: any
    }
}
export interface CreateGroupAction {
    type: typeof CREATE_GROUP,
    payload: {
        group: group
    }
}
export interface DeleteGroupAction {
    type: typeof DELETE_GROUP,
    payload: {
        groupId: string
    }
}
export interface UpateGroupAction {
    type: typeof UPDATE_GROUP,
    payload: {
        updateGroup: updateGroup
    }
}
export interface AddGroupSuccessAction {
    type: typeof GROUP_SUCCESS,
    payload: {
        message: string
    }
}
export interface GroupErrorAction {
    type: typeof GROUP_ERROR,
    payload: {
        message: string
    }
}
export interface GroupClearErrorAction {
    type: typeof GROUP_CLEAR_ERROR,
}

export interface GroupClearSuccessAction {
    type: typeof GROUP_CLEAR_SUCCESS,
}
export interface clearGroupData {
    type: typeof CLEAR_GROUP_DATA,
}
export interface DeleteGroupSuccessAction {
    type: typeof DELETE_GROUP_SUCCESS,
    payload: {
        message: string
        id: string
    }
}
export interface UpdateGroupSuccessAction {
    type: typeof UPDATE_GROUP_SUCCESS,
    payload: {
        message: string
    }
}

export interface AddMemberToGroupAction {
    type: typeof ADD_MEMBER_TO_GROUP,
    payload: {
        id: string,
        email: string
    }
}

export interface DeleteMember {
    type: typeof DELETE_MEMBER,
	payload: {
        message: string
    }
}

export interface AddMemberToGroupSuccessAction {
    type: typeof ADD_MEMBER_TO_GROUP_SUCCESS,
    payload: {
        message: string
    }
}

export interface AddMemberToGroupeErrorAction {
    type: typeof ADD_MEMBER_TO_GROUP_ERROR,
    payload: {
        message: string
    }
}

export type GroupActionTypes = CreateGroupAction | DeleteMember | clearGroupData | GetGroupsAction | DeleteGroupAction | GroupLoadingAction | AddGroupSuccessAction | GroupErrorAction | GroupClearErrorAction | GetGroupAction |
    GroupClearSuccessAction | DeleteGroupSuccessAction | UpateGroupAction | UpdateGroupSuccessAction | AddMemberToGroupAction | AddMemberToGroupSuccessAction | AddMemberToGroupeErrorAction;