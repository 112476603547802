import React from "react";
import styles from "./styles.module.css";
import { useTranslation } from 'react-i18next'

import Content from './Content'

interface Props {
    caption?: string
}
const Loader = ({ caption = 'Loading data...' }: Props) => {
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <Content caption={t(caption)} />
        </div>)
}

export default Loader;