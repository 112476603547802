import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INITIAL_STATE } from "./types";

const initialState: INITIAL_STATE = {
	loading: false,
	error: null,
	data: [],
};

const commentsSlice = createSlice({
	name: "comments",
	initialState,
	reducers: {
		getComments: (state, action: PayloadAction<{ taskId: string }>) => {
			const temp = state.data.find(
				(el) => el.task === action.payload.taskId
			);
			state.loading = temp && temp.list ? false : true;
			state.error = null;
		},
		setComments: (
			state,
			action: PayloadAction<{
				taskId: string;
				data: any;
			}>
		) => {
			state.loading = false;
			state.error = null;
			const index = state.data.findIndex(
				(el) => el.task === action.payload.taskId
			);
			if (index > -1)
				state.data[index] = {
					task: action.payload.taskId,
					list: action.payload.data,
				};
			else
				state.data.push({
					task: action.payload.taskId,
					list: action.payload.data,
				});
		},
		getTemplateComments: (state, action: PayloadAction<{ templateId: string }>) => {
			const temp = state.data.find(
				(el) => el.task === action.payload.templateId
			);
			state.loading = temp && temp.list ? false : true;
			state.error = null;
		},
		setTemplateComments: (
			state,
			action: PayloadAction<{
				templateId: string;
				data: any;
			}>
		) => {
			state.loading = false;
			state.error = null;
			state.data = action.payload.data;
		},
		errorComments: (state, action: PayloadAction<{ message: string }>) => {
			state.loading = false;
			state.error = action.payload.message;
		},
	},
});

export const {
	getComments,
	setComments,
	getTemplateComments, 
	setTemplateComments,
	errorComments,
} = commentsSlice.actions;

export default commentsSlice.reducer;
